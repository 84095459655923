@import "./mixins/flex.scss";
@import "./mixins/fonts.scss";

.chWrap {
  background: var(--bg-secondary);
  position: relative;
  .chContainer {
    padding: 32px 45px;
    .content {
      .menu {
        @include flexNilCenterGapCustom(30px);
        position: relative;
        z-index: 2;
        .menuItem {
          .title {
            @include setFontTertiaryBold(28px, var(--text-faded));
            text-transform: uppercase;
            border-bottom: 2px solid transparent;
            cursor: pointer;
          }
          .titleActive {
            @include setFontTertiaryBold(28px, var(--clr-secondary));
            border-bottom: 2px solid var(--clr-secondary);
          }
        }
      }

      .list {
        margin-top: 28px;
        @include flexNilCenterGapCustom(20px);
        flex-wrap: nowrap;
        overflow: auto;
        padding-bottom: 18px;

        .listItem {
          min-width: 200px;
          border-radius: 10px;
          overflow: hidden;
          cursor: pointer;
          position: relative;
          border: 1px solid var(--border);

          .content {
            background-color: var(--bg-primary);
            @include flexCenterCenter();
            padding: 10px 0px;

            h3 {
              @include setSecondarySemiBold(18px, var(--clr-primary));
            }
          }
          .header {
            height: 160px;
            position: relative;
            .image {
              width: 100%;
              height: 100%;
            }
          }
          .header::before {
            content: "";
            background-image: linear-gradient(
              180deg,
              #eff0ea00 0%,
              #19263f 95%
            );
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .bannerWave {
    // background: url(../public/images/banner/wave.svg) 80% 0 repeat-x;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-size: contain;
    position: absolute;
  }
}
